import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout/Layout'
import FishingPole from '../img/fishing_pole.png'

const NotFoundPage = () => (
  <Layout>
    <section className="section section--gradient textured-bg">
      <div className="container page-content" style={{minHeight:"calc(100vh - 300px)", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <img src={FishingPole} alt="fishing pole image" width="85px" alt="fishing pole" style={{float:"left", marginTop:"15px", marginBottom:"25px"}}/>
        <h1>Looks like we hit a snag in our line.</h1>
        <p style={{fontSize:"20px"}}>Head on back <Link to="/">home</Link>.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
